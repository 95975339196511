import { useState } from 'react';
import { FileInput } from 'react-md';

import * as XLSX from 'xlsx';

import './ManageInCharge.css';
import * as NAV from '@utilities/constants/navigation';
import * as IMG from '@utilities/constants/images';
import Breadcrumb from '@components/Breadcrumb.js';
import usePageFramework from '@utilities/hooks/usePageFramework';
import api from '@utilities/claApi';

const UploadButton = (props) => {
    const { onChange, height } = props;

    return (
        <FileInput
            data-testid='in-charge-upload-button'
            id={`in-charge-upload-button`}
            onChange={onChange}
            theme='clear'
            themeType='flat'
            buttonType ='text'
            multiple={false}
            accept='.xls, .xlsx'
            icon= {<img height={height} alt="File upload button" src={IMG.uploadIconPrimary} />}>
        </FileInput>
    );
};

function ManageInCharge(props) {
    const [results, setResults] = useState(null);
    const {
        dispatch,
        ACTION,
    } = usePageFramework();

	const navItems = [
		{ to: NAV.ADMIN_DASHBOARD, label: 'Admin Dashboard' },
		{ to: NAV.MANAGE_IN_CHARGE, label: 'In-Charge', current: true }
	];

    const showLoadingDialog = () => {
        dispatch(ACTION.setProgressText('Processing In-Charge Assignments...'));
		dispatch(ACTION.setProgressVisible(true));
    };

    const showFinishedDialog = (message) => {
		dispatch(ACTION.setProgressVisible(false));
		dispatch(ACTION.setShowCustomDialog(true));
		dispatch(ACTION.setCustomDialogTitle('Finished Processing In-Charges'));
		dispatch(ACTION.setCustomDialogMsg('The results will be displayed in-page'));
        setResults(message);
    };

    const showErrorDialog = (err) => {
		dispatch(ACTION.setProgressVisible(false));
		dispatch(ACTION.setShowCustomDialog(true));
		dispatch(ACTION.setCustomDialogTitle('Error!'));
		dispatch(ACTION.setCustomDialogMsg(err));
    };

    const handleFileUpload = (e) => {
        const inChargeAssignmentsFile = e.target.files[0];
        showLoadingDialog();
        
        var reader = new FileReader();
        reader.readAsArrayBuffer(inChargeAssignmentsFile);
        reader.onload = readFileContent(reader);
    };

    const readFileContent = (reader) => async (e) => {
        try {
            const data = new Uint8Array(reader.result);
            const wb = XLSX.read(data, { type: 'array', sheetRows: 2000 });
            const ws = wb.Sheets[wb.SheetNames[0]];
            const rows = XLSX.utils.sheet_to_json(ws);
            rows.splice(0, 1); // Remove header descriptor row

            if (!rows.length) {
                showFinishedDialog('No assignments to process');
                return;
            }

            const users = await api.get('/users').then((response) => {
                return response.data.results;
            });

            const organizers = await api.get('/organizers').then((response) => {
                return response.data.results;
            });

            if (!users.length) {
                showFinishedDialog('No In-Charge users available for assignments');
                return;
            }
            
            if (!organizers.length) {
                showFinishedDialog('No organizers available for assignments');
                return;
            }

            const lookupPromises = rows.map(async (row, index) => {
                const { 'Engagement ID or Engagement #': EngagementId, 'Account #': AccountNumber, 'Project Fiscal Year': Year, 'In-Charge E-Mail': InChargeEmail } = row;
                const accountNumber = AccountNumber || EngagementId?.split('-')?.[0];
                const year = Year || EngagementId?.split('-')?.[2];
                const rowNumber = index + 1;

                if (!accountNumber) {
                    return { invalidRow: `Row ${rowNumber}: unable to parse account number` };
                }
                if (!year) {
                    return { invalidRow: `Row ${rowNumber}: unable to parse fiscal year` };
                }
                if (!InChargeEmail) {
                    return { invalidRow: `Row ${rowNumber}: unable to parse in-charge email` };
                }

                if (!InChargeEmail.includes('@claconnect.com')) {
                    return { invalidRow: `Row ${rowNumber}: in-charge email (${InChargeEmail}) is not a @claconnect.com email` };
                }

                const organizer = await api.get(`/organizers?filter=year eq ${year}&filter=client.number eq ${accountNumber}`).then((response) => {
                    return response.data.results[0];
                }).catch((err) => {
                    console.log(err);
                    return null;
                })

                if (!organizer) {
                    return { invalidRow: `Row ${rowNumber}: No organizer of account number (${accountNumber}) and year (${year}) found` };
                }

                if (InChargeEmail.toLowerCase().trim() === organizer?.inCharge?.email?.toLowerCase()?.trim()) {
                    return { invalidRow: `Row ${rowNumber}: Organizer of account number (${accountNumber}) and year (${year}) is already assigned to ${InChargeEmail}`};
                }

                const inChargeUser = await api.get(`/users?email=${InChargeEmail}`).then((response) => {
                    return response?.data?.results?.[0];
                }).catch((err) => {
                    console.log(err);
                    return null;
                });

                if (!inChargeUser) {
                    return { invalidRow: `Row ${rowNumber}: No user with In-Charge email of (${InChargeEmail}) found` };
                }
                
                return {
                    organizerId: organizer.id,
                    inChargeId: inChargeUser.id,
                    rowNumber,
                    AccountNumber: accountNumber,
                    Year: year,
                    InChargeEmail,
                };
            });

            const assignments = await Promise.all(lookupPromises);

            const failedRows = assignments.filter(x => x.invalidRow);
            const foundRows = assignments.filter(x => !x.invalidRow);

            var message = '';
            message = message.concat(`${failedRows.length} rows were not processed:\n`);
            message = message.concat(`${failedRows.map(x => x.invalidRow).join('\n')}\n\n`);
            message = message.concat(`${foundRows.length} assignments out of ${rows.length} rows found to process:\n`);
            const assignmentPromises = foundRows.map((foundRow) => {
                const { organizerId, inChargeId, rowNumber, AccountNumber, Year, InChargeEmail, } = foundRow;

                return api.put(`/organizers/${organizerId}`, { inChargeId }).then(() => {
                    return Promise.resolve(`Row ${rowNumber}: In-Charge (${InChargeEmail}) has been assigned to Account number (${AccountNumber}) and year (${Year})`);
                }).catch((err) => {
                    console.log(err);
                    return Promise.reject(`Row ${rowNumber}: In-Charge (${InChargeEmail}) has failed to be assigned to Account number (${AccountNumber}) and year (${Year})`);
                });
            });

            const resolution = await Promise.allSettled(assignmentPromises);
            const finishedAssignments = resolution.filter(x => x.status === 'fulfilled');
            const rejectedAssignments = resolution.filter(x => x.status === 'rejected');

            message = message.concat(`${finishedAssignments.length} In-Charge emails assigned\n`);
            message = message.concat(`${finishedAssignments.map(x => x.value).join('\n')}\n\n`);
            message = message.concat(`${rejectedAssignments.length} In-Charge emails failed to be assigned\n`);
            message = message.concat(`${rejectedAssignments.map(x => x.reason).join('\n')}\n\n`);

            showFinishedDialog(message);
        } catch (error) {
            console.log(error);
            showErrorDialog(error);
        }
    };

    return (
        <div className="pracDashboardSize">
            <Breadcrumb items={navItems} />
			<h1>Assign In-Charge Users to Organizers</h1>
            <p className='page-instructions'>
                Upload an excel file to assign In-Charges to Organizers. The excel file should follow the format of the provisioner template with an addition column named "In-Charge E-Mail". In-Charge email(s) must be a "@claconnect.com" email and exist as a user in the Exchange. This process will utilize the Account Number (column A or G), Year (columnn F), and the additional column (column In-Charge E-Mail). The maximum number of rows that can be processed at once is 2000 rows. The results will be displayed below.
            </p>
            <UploadButton onChange={handleFileUpload} />

            { results && <p className='page-result-title'><b>Results:</b></p> }
            { results && <p className='page-result-text'>{results}</p> }
            
        </div>
    );
};

export default ManageInCharge;