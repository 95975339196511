import { useState, useRef } from 'react';
import { Checkbox, useToggle, Button } from 'react-md';

// custom modules
import usePageFramework from '@utilities/hooks/usePageFramework';
import WarningDialog from '@components/WarningDialog';
import api from '@utilities/claApi';
import * as IN_PREP_STRINGS from '@utilities/constants/inPrepLock';
import * as PUSH_STATUS from '@utilities/constants/pushStatus';
import usePolling from '@utilities/hooks/usePolling';

function InPrepCheckbox(props) {
    const { index, data, changeData } = props;
    const { dispatch, ACTION, clearFormState, history, NAV } = usePageFramework();
    const isLocked = useRef(data?.locked ?? false);
    const [isChecked, setIsChecked] = useState(data?.locked ?? false);
    const [visibleWarningDialog, showWarningDialog, hideWarningDialog] = useToggle(false);
    const clientFirstName = data?.client?.name ?? '';

    const pollErrorFn = () => {
        hideProgressDialog();
        showErrorDialog();
        setIsChecked(false);
    };

    const pollProps = { pollErrorFn };
    const { startPolling } = usePolling(pollProps);

    const handleCheckboxClick = (event) => {
        event.target.checked = !event.target.checked;
        event.stopPropagation();
        showWarningDialog();
    };

    const onConfirmWarning = () => {
        onInPrepConfirm();
        hideWarningDialog();
    };

    const setChangeData = () => {
        changeData(
            data.id,
            [
                { column: 'locked_on', value: new Date() },
                { column: 'locked', value: true },
                { column: 'updatedOn', value: new Date() },
                { column: 'status', value: 'Complete' },
            ]
        );
    };

    const showProgressDialog = () => {
        dispatch(ACTION.setProgressText(IN_PREP_STRINGS.IN_PROGRESS_TEXT));
        dispatch(ACTION.setProgressVisible(true));
    };

    const hideProgressDialog = () => {
        dispatch(ACTION.setProgressText(''));
        dispatch(ACTION.setProgressVisible(false));
    };

    const showErrorDialog = (message) => {
        dispatch(ACTION.setShowCustomDialog(true));
        dispatch(ACTION.setCustomDialogTitle(IN_PREP_STRINGS.ERROR_TITLE));

        if (message) {
            dispatch(ACTION.setCustomDialogMsg(message));
        } else {
            dispatch(ACTION.setCustomDialogMsg(IN_PREP_STRINGS.ERROR_TEXT(clientFirstName)));
        }
    };

    const lockOrganizer = () => {
        const organizerData = {
            locked: true,
            status: 'Complete',
        };

        return api.put(`/organizers/${data.id}`, organizerData);
    };

    const createPollFn = (pushStatusId) => async () => {
        return api.get(`/organizers/${data.id}/push/${pushStatusId}`).then((res) => {
            return res.data;
        }).then(async (pushResult) => {
            const isValidStatus = (status) => PUSH_STATUS.PUSH_STATUSES.includes(status);

            if (!isValidStatus(pushResult?.status)) {
                pollErrorFn();
                return false;
            }

            switch (pushResult.status) {
                case PUSH_STATUS.SUCCESS:
                    hideProgressDialog();
                    setChangeData();
                    setIsChecked(true);
                    await lockOrganizer();
                    return false;
                case PUSH_STATUS.FAIL:
                    // NEED TO UNCOMMENT WHEN MERGING TO MAIN !!!
                    hideProgressDialog();
                    showErrorDialog(pushResult?.summary);
                    setIsChecked(false);
                    return false;

                // START !!! NEED TO COMMENT WHEN MERGING TO MAIN !!! 
                    // hideProgressDialog();
                    // setChangeData();
                    // setIsChecked(true);
                    // await lockOrganizer();
                    // return false;
                // END !!! NEED TO COMMENT WHEN MERGING TO MAIN !!! 
                default:
                    return true;
            }
        }).catch((err) => {
            console.log(err)
            pollErrorFn();
            return false;
        });
    };

    const onInPrepConfirm = () => {
        showProgressDialog();
        setIsChecked(true);

        api.post(`/organizers/${data.id}/push`, { returnType: 'X' }).then((res) => {
            return res.data;
        }).then((data) => {
            const pushResultStatusId = data.id;
            const fn = createPollFn(pushResultStatusId);

            startPolling(fn);
        }).catch((err) => {
            console.log(err);
            pollErrorFn();
        });
    };

    const handlePdfButton = () => {
        clearFormState();
        history.push(`${NAV.ORGANIZER_EXPORT}/${data.id}`);
        window.scrollTo(0, 0);
        dispatch(ACTION.setProgressText('Loading Client Data...'));
        dispatch(ACTION.setProgressVisible(true));
        dispatch(ACTION.setCurrentCardKey(null));
    };
   
    const pdfExportButton = () => {
        return (
            <Button 
                id={`pdf-button-${index}`}
                key={`pdf-button-${index}`}
                className={isLocked.current || isChecked ? 'viewButtonActive' : 'viewButtonInActive'} disabled={ !isChecked }
                onClick={handlePdfButton}> {isLocked.current || isChecked ? 'PDF Export' : ''}
            </Button>
        );
    };

    const buildCheckbox = () => {
        return (
            <Checkbox
                data-testid={`inPrep-chkbox-${index}`}
                id={`inPrep-chkbox-${index}`}
                key={`inPrep-chkbox-${index}`}
                onClick={handleCheckboxClick}
                checked={isLocked.current || isChecked}
                disabled={isLocked.current || isChecked}
                onChange={() => { }}
                label={pdfExportButton()}
                value={isLocked.current || isChecked}
            />
        );
    };

    return (
        <WarningDialog
            subtitleText={IN_PREP_STRINGS.SUBTITLE_TEXT(clientFirstName)}
            questionText={IN_PREP_STRINGS.QUESTION_TEXT(clientFirstName)}
            onConfirmClick={onConfirmWarning}
            buttonElement={buildCheckbox()}
            visible={visibleWarningDialog}
            onCancelWarning={hideWarningDialog}
        />
    );
}

export default InPrepCheckbox;