import * as EVENT from '@utilities/constants/triggerKeys';

const triggeredEvent = (trigger, event, options) => {
    switch (trigger) {
        default:
            break;
    }
};

const showGovBodyConflictEnfDesc = (options) => {
    const { section } = options;

    const govBodyConflictPlcy = section.groups[5].fields[1].default === EVENT.KEY_YES
    const govBodyConflictEnf = section.groups[7].fields[1].default === EVENT.KEY_YES

    if (govBodyConflictPlcy && govBodyConflictEnf) {
        section.groups[8].isVisible = true
    } else {
        section.groups[8].isVisible = false
    }
};

const showConflictInterestFields = (options) => {
    const { section, field } = options;

    section.groups[6].isVisible = field.default === EVENT.KEY_YES;
    section.groups[7].isVisible = field.default === EVENT.KEY_YES;
};

const showGovBodyWrtnPolLocalChpts = (options) => {
    const { section, field } = options;
    const isGovBodyWrtnPolLocalChptsNo = section.groups[1].fields[1].default === EVENT.KEY_NO;

    section.groups[1].isVisible = field.default === EVENT.KEY_YES;
    section.groups[2].isVisible = isGovBodyWrtnPolLocalChptsNo && field.default === EVENT.KEY_YES;
};

const showGovBodyWrtnPolLocalChptsExp = (options) => {
    const { section, field } = options;

    section.groups[2].isVisible = field.default === EVENT.KEY_NO;
};

const showGovBodyPowerApptNonGovBodyData = (options) => {
    const { section, field } = options;

    section.groups[16].isVisible = field.default === EVENT.KEY_YES;
};

const showGovBodyPowerApptGovData = (options) => {
    const { section, field } = options;

    section.groups[14].isVisible = field.default === EVENT.KEY_YES;
};

const showGovBodyMbrsOrStockData = (options) => {
    const { section, field } = options;

    section.groups[12].isVisible = field.default === EVENT.KEY_YES;
};

const showMaterialDiversionFields = (options) => {
    const { section, field } = options;
    section.groups[10].isVisible = field.default === EVENT.KEY_YES;
};

const showOrgDelFields = (options) => {
    const { section, field } = options;

    section.groups[8].isVisible = field.default === EVENT.KEY_YES;
    section.groups[9].isVisible = field.default === EVENT.KEY_YES;
};

const showGovBodyVoteMbrDifDscdiff = (options) => {
    const { section, field } = options;

    section.groups[2].isVisible = field.default === EVENT.KEY_YES;
};

const showGovBodyVoteMbrDlgtDsc = (options) => {
    const { section, field } = options;

    section.groups[4].isVisible = field.default === EVENT.KEY_YES;
};

const showgovBodyContempDocExp = (options) => {
    const { section, field } = options;
    section.groups[18].isVisible = field.default === EVENT.KEY_NO;
};

const showGovBodyOffFamRelData = (options) => {
    const { section, field } = options;

    section.groups[6].isVisible = field.default === EVENT.KEY_YES;
};
const showgovBodyCompRevOthFields = (options) => {
    const { section } = options;
    section.groups[13].isVisible = section.groups[11].fields[1].default === EVENT.KEY_YES || section.groups[12].fields[1].default === EVENT.KEY_YES;
    section.groups[14].isVisible = section.groups[12].fields[1].default === EVENT.KEY_YES;
};

const showgovBodyInvestPolicy = (options) => {
    const { section, field } = options;

    section.groups[16].isVisible = field.default === EVENT.KEY_YES;
};
const showGovBody990FileStates = (options) => {
    const { section, field } = options;
    section.groups[1].isVisible = field.default === EVENT.KEY_YES;
};
const showGovBodyPublcInspOther = (options) => {
    const { section, field } = options;
    section.groups[7].isVisible = field.default;
}
const showGovBodyPublcInspNotExp = (options) => {
    const { section, field } = options;
    section.groups[9].isVisible = field.default;
}
const showGovBodyPublcInspProcDesc = (selectedList) => {
    const { section, field } = selectedList;
    section.groups[11].isVisible = (field.default.length > 0);
}
const showDisclosureStateProv = (options) => {
    const { section, field } = options;
    const isUnitedStates = field.default === EVENT.KEY_UNITED_STATES;
    const isCanada = field.default === EVENT.KEY_CANADA;
    const isOther = !isUnitedStates && !isCanada;

    section.groups[12].fields[5].isVisible = isUnitedStates;
    section.groups[12].fields[6].isVisible = isOther;
    section.groups[12].fields[7].isVisible = isCanada;

}


const sectionFieldLogic = (logicFunction, options) => {
    switch (logicFunction) {
        case 'showGovBodyConflictEnfDesc':
            showGovBodyConflictEnfDesc(options);
            break;
        case 'showConflictInterestFields':
            showConflictInterestFields(options);
            break;
        case 'showGovBodyWrtnPolLocalChpts':
            showGovBodyWrtnPolLocalChpts(options);
            break;
        case 'showGovBodyWrtnPolLocalChptsExp':
            showGovBodyWrtnPolLocalChptsExp(options);
            break;
        case 'showGovBodyPowerApptNonGovBodyData':
            showGovBodyPowerApptNonGovBodyData(options);
            break;
        case 'showGovBodyPowerApptGovData':
            showGovBodyPowerApptGovData(options);
            break;
        case 'showGovBodyMbrsOrStockData':
            showGovBodyMbrsOrStockData(options);
            break;
        case 'showMaterialDiversionFields':
            showMaterialDiversionFields(options);
            break;
        case 'showOrgDelFields':
            showOrgDelFields(options);
            break;
        case 'showGovBodyVoteMbrDifDscdiff':
            showGovBodyVoteMbrDifDscdiff(options);
            break;
        case 'showGovBodyVoteMbrDlgtDsc':
            showGovBodyVoteMbrDlgtDsc(options);
            break;
        case 'showGovBodyOffFamRelData':
            showGovBodyOffFamRelData(options);
            break;
        case 'showgovBodyCompRevOthFields':
            showgovBodyCompRevOthFields(options);
            break;
        case 'showgovBodyInvestPolicy':
            showgovBodyInvestPolicy(options);
            break;
        case 'showGovBody990FileStates':
            showGovBody990FileStates(options);
            break;
        case 'showGovBodyPublcInspProcDesc':
            showGovBodyPublcInspProcDesc(options);
            break;
        case 'showGovBodyPublcInspNotExp':
            showGovBodyPublcInspNotExp(options);
            break;
        case 'showGovBodyPublcInspOther':
            showGovBodyPublcInspOther(options);
            break;
        case 'showgovBodyContempDocExp':
            showgovBodyContempDocExp(options);
            break;

        case 'showDisclosureStateProv':
            showDisclosureStateProv(options);
            break;
        default:
            break;
    }
};

export {
    triggeredEvent,
    sectionFieldLogic
};