// common modules
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import FormData from 'form-data';
import moment from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import _ from 'lodash';

// custom modules
import * as INPUTSTATE from '@utilities/constants/inputState';
import * as REDUX from '@utilities/constants/redux';
import * as NAV from '@utilities/constants/navigation';
import * as CARDSTATUS from '@utilities/constants/cardStatus';
import * as ACTION from '@utilities/actions/global';
import * as STRING from '@utilities/constants/strings';
import { selectReduxGlobalState } from '@utilities/store';
import api from '@utilities/claApi';
import { parseFormData } from '@utilities/formData/formDataParser';
import { getCardProp } from '@utilities/helpers/getCardProp';
import InvalidFilesMessage from '@components/dialog/customDialog/invalidFilesMessage';
import ExtensionErrorFilesMessage from '@components/dialog/customDialog/extensionErrorFileMessage';
import { downloadDocumentFile } from '@utilities/apis/documentsApi';
import { convertImageToPdf, isFileToConvertToPdf, getFileName, renderTiffImage} from '@utilities/files/convertImageToPdf';
import useDocumentPreview from '@components/documentPreview/useDocumentPreview';
import { calculateDashboardSummary } from '@utilities/helpers/calculateDashboardSummary';

let client;
let organizerId = null;
let dashboardId = null;

const currentYear = 2022;
const appType = '990';

let currentEntity = {};

function usePageFramework(page) {
	let card = null;

	client = useSelector(state => selectReduxGlobalState(state, REDUX.CURRENT_USER));
	organizerId = useSelector(state => selectReduxGlobalState(state, REDUX.ORGANIZER_ID));
	dashboardId = useSelector(state => selectReduxGlobalState(state, REDUX.DASHBOARD_ID));

	const dashboard = useSelector(state => selectReduxGlobalState(state, REDUX.DASHBOARD));
	const currentCardKey = useSelector(state => selectReduxGlobalState(state, REDUX.CURRENT_CARD_KEY));
	const screenSize = useSelector(state => selectReduxGlobalState(state, REDUX.SCREEN_SIZE));
	const uploadList = useSelector(state => selectReduxGlobalState(state, REDUX.UPLOAD_LIST));
	const activeReturn = useSelector(state => selectReduxGlobalState(state, REDUX.ACTIVE_RETURN));
	const catCardProp = page ? 'route' : 'key';
	const catCardCompare = page ? page : currentCardKey;
  const { buildDocumentPreview } = useDocumentPreview();

	// category.cards.find fails on test because cards can be empty on load
	dashboard?.findIndex((category) => category.cards && category.cards.find((catCard) => {
		const isFound = catCard[catCardProp] === catCardCompare;
		if (isFound) {
			card = catCard;
		}
		return isFound;
	}));

	const updateStatusCard = (statusId, dashCard) => {
		card = dashCard;
		updateStatus(statusId);
	};

	const updateStatus = (statusId) => {
		dispatch(ACTION.setCard(card));

		if (card) {
			card.statusId = statusId;
			card.statusTitle = CARDSTATUS.STATUS_TITLE[statusId];

			dispatch(ACTION.setDashboard(dashboard));

			const dashboardData = { dashboard: dashboard };
			const {summary} = calculateDashboardSummary(dashboard);
			const data = { dashboardSummary: summary };

			api.put(`/organizers/${organizerId ?? ''}/dashboard/${dashboardId}`, dashboardData).catch((error) => {
				console.log(error);
				console.log('Unable to save dashboard');
				dispatch(ACTION.setIsSaveSuccess(false));
			});

			api.put(`/organizers/${organizerId ?? ''}`, data).catch((error) => {
				console.log(error);
				console.log('Unable to save dashboardSummary');
				dispatch(ACTION.setIsSaveSuccess(false));
			});
		}
	};

	const updateVisibleDashboardCards = (filterstatusId) => dispatch(ACTION.setDashboardFilterStatus(filterstatusId));
	const updateSortedDashboardCards = (sortBy) => dispatch(ACTION.setDashboardSortRequirements(sortBy));
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const selectState = (stateLabel) => useSelector(state => selectReduxGlobalState(state, stateLabel));
	// let currentCardStatus = selectState(REDUX.CURRENT_CARD_STATUS);

	const setGlobalFormState = (formName, payload) => {
		const parsedFormData = parseFormData(payload);
		currentEntity[formName] = parsedFormData;
		dispatch(ACTION.setForm(formName, parsedFormData));

		const cardStatusId = 2;

		const data = { key: formName, data: parsedFormData, status: cardStatusId };

		api.put(`/forms/${organizerId ?? ''}`, data).catch((error) => {
			console.log(error);
			console.log('Unable to save form');
			dispatch(ACTION.setIsSaveSuccess(false));
		});

	};

	const updatePage = (pageInfo) => {
		dispatch(ACTION.setToolbarTitle(pageInfo.title));
		dispatch(ACTION.setCurrentCardKey(pageInfo.key));
		dispatch(ACTION.showBulkUpload(false));
		dispatch(ACTION.setDashboardFilterStatus(CARDSTATUS.REQUIRED));
		dispatch(ACTION.setDashboardSortRequirements('traditional'));
	};

	const updateCard = () => {
		if (card) {
			dispatch(ACTION.setToolbarTitle(getCardProp(card.formName, 'title')));
			dispatch(ACTION.setCurrentCardKey(card.key));
			dispatch(ACTION.setCurrentCardStatus(null));

			if (card.showBulkUpload) {
				dispatch(ACTION.showBulkUpload(true));
				dispatch(ACTION.setDashboard(dashboard));
			} else {
				dispatch(ACTION.showBulkUpload(false));
			}
		} else {
			dispatch(ACTION.showBulkUpload(true));
			dispatch(ACTION.setCurrentCardKey(STRING.DASHBOARD_KEY));
		}
	};

	const history = useHistory();
	const dispatch = useDispatch();
	const store = useStore();
	const location = useLocation();

	const clearFormState = () => {
		const state = store.getState();
		state.get('global').forEach((value, key) => {
			if (!key.startsWith('G_') && !key.startsWith('@')) { // Only Keep Global Variables from being cleared!
				dispatch({ type: key, payload: null });
			}
		});
	};

	const getOrganizerDocumentTarget = () => {
		return organizerId;
	};

	const replaceNbspWithSpaceAndGetPositions = (str) => {
		const positions = [];
		let newStr = '';
		for (let i = 0; i < str.length; i++) {
			// Remove char where nbsp;
			if (str.charCodeAt(i) === 160 || str.charCodeAt(i) === 32 || str.charCodeAt(i) === 8239) {
				positions.push(i);
				newStr += ' ';
			} else {
				newStr += str[i];
			}
		}
		return newStr;
	};

	const onFileUpload = async (event, acceptedFiles, section, list, fn, form = card.key) => {
		const files = acceptedFiles ? acceptedFiles : event.target.files;
		if (!files) return;

		//check duplicate files
		const duplicateFiles = [];
		const filesCopy = [];
        let fileName;
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			fileName = replaceNbspWithSpaceAndGetPositions(file.name);

			const dupFile = uploadList?.find(upload => upload.name === fileName);
			if (dupFile) {
				duplicateFiles.push(dupFile);
			}
			filesCopy.push(file);
		}

		const uploadsProps = { files: filesCopy, section, fn, form };
		if (!duplicateFiles.length) {
			uploadFiles(files, section, fn, form, list);
		} else {
			dispatch(ACTION.setUploadWarningVisible(true));
			dispatch(ACTION.setDuplicateUploadFiles(duplicateFiles));
			dispatch(ACTION.setUploadsProps(uploadsProps));
		}
	};

	const uploadFiles = async (files, section, fn, form, list) => {
		if (!files) return;

		dispatch(ACTION.setProgressText('Uploading...'));
		dispatch(ACTION.setProgressVisible(true));
		dispatch(ACTION.setUploadProgressVisible(true));

		const uploadPromises = [];
		const pattern = /['~"#%&*:<>?/\\{|}]/g;
		const invalidFiles = [];

		for (let index = 0; index < files.length; index++) {
			const file = files[index];

			const fileNameAndExtension = files[index].name.split('.');
			fileNameAndExtension.pop();
			const fileName = fileNameAndExtension.join('');

			const matched = fileName.match(pattern);

			if (matched !== null) {
				invalidFiles.push(files[index].name);
				dispatch(ACTION.setProgressVisible(false));
				dispatch(ACTION.setCustomDialogTitle(''));
				dispatch(ACTION.setShowCustomDialog(true));
				dispatch(ACTION.setCustomDialogMsg(<InvalidFilesMessage invalidFiles={invalidFiles} />));
				continue;
			}

			// GROWTH: Client side file size validation for uploading documents
			uploadPromises.push(uploadDocument(file, form, section));
		};

		const results = await Promise.all(uploadPromises);

		const successfulUploads = results.filter(x => x !== undefined);

		// if upload is succesful
		if (!results.includes(undefined)) {
			dispatch(ACTION.setUploadList([...list, ...results]));
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setUploadProgressVisible(false));

			// save form when file uploaded
			fn?.updateState && fn.updateState()
		} else if (extensionErrorFiles.length > 0) {
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setUploadProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle('Upload Error!'));
			dispatch(ACTION.setCustomDialogMsg(<ExtensionErrorFilesMessage extensionErrorFiles={extensionErrorFiles} />));
			dispatch(ACTION.setShowCustomDialog(true));
			dispatch(ACTION.setUploadList([...list, ...successfulUploads]));
		} else {
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setUploadProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle('Upload Error!'));
			dispatch(ACTION.setCustomDialogMsg('Please try again later.'));
			dispatch(ACTION.setShowCustomDialog(true));
			dispatch(ACTION.setUploadList([...list, ...successfulUploads]));
		}
	};

	const extensionErrorFiles = [];

	const uploadDocument = async (file, form, section) => {
		const formPayload = new FormData();
		formPayload.append('document', file);

		if (form !== null && form !== undefined) {
			formPayload.append('form', form);
		}

		if (section !== null && section !== undefined) {
			formPayload.append('section', section);
		}

		let targetOrganizerId = getOrganizerDocumentTarget();

		return api.post(`/organizers/${targetOrganizerId}/documents/upload`, formPayload).then((response) => {
			return response.data.id;
		}).then((documentId) => {
			return api.get(`/organizers/${targetOrganizerId}/documents/${documentId}`);
		}).then((response) => {
			return response.data;
		}).then((document) => {
			/*
				GROWTH: remove this transformation
					This transformation is done due to compatability with
					form renderer, document manager, and sharepoint implementation
			*/
			document.updated_on = document.updatedOn;
			document.downloaded_on = document.downloadedOn;
			document.upload_id = document.id;
			document.uploaded_by = document.createdBy;
			return document;
		}).catch((err) => {
			console.error(err);
			console.error('error message', err.response.data.message)
			if (err && err.response.data.message === "The file extension must be of type: png, jpeg, jpg, pdf, doc, docx, xls, xlsx, tif, tiff, csv, or txt") {
				extensionErrorFiles.push(file.name)
			}
		});
	};

	const onFileRemoval = (event, file, replacedFiles) => {
		let targetOrganizerId = getOrganizerDocumentTarget();

		return api.delete(`/organizers/${targetOrganizerId}/documents/${file.id}`).then((response) => {
			dispatch(ACTION.setUploadList(uploadList.filter((u) => u.id !== file.id)));
			if (!replacedFiles) dispatch(ACTION.setProgressVisible(false));
			return response
		}).catch((err) => {
			console.error(err);
			dispatch(ACTION.setProgressText('Failed to remove document!'));
		});
	};

	const moveFile = (fileId, key, section) => {
		dispatch(ACTION.setProgressVisible(true));
		dispatch(ACTION.setProgressText('Moving...'));

		let targetOrganizerId = getOrganizerDocumentTarget();
		const payload = {
			form: key,
			section: section
		};
		return api.put(`/organizers/${targetOrganizerId}/documents/${fileId}`, payload).then((response) => {
			for (let index = 0; index < uploadList.length; index++) {

				if (uploadList[index].id === fileId) {
					uploadList[index].form = key;
					break;
				}
			}
			const categoryCount = [];
			const fileCategory = _.groupBy(uploadList, "form");
			for (var cat in fileCategory) {
				categoryCount.push(fileCategory[cat].length);
			}
			dispatch(ACTION.setUploadList([...uploadList]));
			dispatch(ACTION.setFileCategoryCount([...categoryCount]));
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle(''));
			dispatch(ACTION.setCustomDialogMsg('File successfully moved'));
			dispatch(ACTION.setShowCustomDialog(true));
			return fileId;
		}).catch((error) => {
			console.error(error);
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle(''));
			dispatch(ACTION.setCustomDialogMsg('File move failed!'));
			dispatch(ACTION.setShowCustomDialog(true));
		});
	};

	const onFilePreview = async (file, config) => {
		let targetOrganizerId = getOrganizerDocumentTarget();

		return downloadDocumentFile(targetOrganizerId, file.id, config);
	};

	const onFileRename = (file, newName) => {
		dispatch(ACTION.setProgressVisible(true));
		dispatch(ACTION.setProgressText('Renaming...'));

		let targetOrganizerId = getOrganizerDocumentTarget();

		const payload = {
			name: newName,
		};

		return api.put(`/organizers/${targetOrganizerId}/documents/${file.id}`, payload).then((response) => {
			file.name = newName;

			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle(''));
			dispatch(ACTION.setCustomDialogMsg('File name successfully renamed'));
			dispatch(ACTION.setShowCustomDialog(true));

			return file;
		}).catch((error) => {
			console.error(error);
			dispatch(ACTION.setProgressVisible(false));
			dispatch(ACTION.setCustomDialogTitle(''));
			dispatch(ACTION.setCustomDialogMsg('File rename failed!'));
			dispatch(ACTION.setShowCustomDialog(true));
		});
	};

	const downloadFile = async (file) => {
		let targetOrganizerId = getOrganizerDocumentTarget();

		if (isFileToConvertToPdf(file)) {
			// image files are to be converted to pdf before download
			await convertImageToPdf(targetOrganizerId, file);
		} else {
			const blobFile = await downloadDocumentFile(targetOrganizerId, file.id);
			const url = URL.createObjectURL(blobFile);
			const a = document.createElement("a");
			a.href = url;
			a.download = file.name;
			document.body.appendChild(a);
			a.click();

			setTimeout(function () {
				document.body.removeChild(a);
				URL.revokeObjectURL(blobFile);
			}, 1000);
		}
	};

	const downloadAllFiles = async () => {
		let targetOrganizerId = getOrganizerDocumentTarget();

		// Package all files into zip
		const uploads = [];
		let zip = new JSZip();
		for (let index = 0; index < uploadList.length; index++) {
			const upload = uploadList[index];
			upload.downloaded_on = `${new Date().toJSON()}`;

			let blobContent = isFileToConvertToPdf(upload)
				? await convertImageToPdf(targetOrganizerId, upload, true)
				: await downloadDocumentFile(targetOrganizerId, upload.id);
			let fileName = getFileName(upload);

			if (!blobContent) {
				throw new Error(`Received no file content for ${upload.name}`);
			}

			zip.file(fileName, blobContent, { blob: true, base64: true });
			uploads.push(upload);
		}
		return zip.generateAsync({ "type": "blob" }).then((content) => {
			saveAs(content, activeReturn.clientNumber + "_" + activeReturn.displayName + "_" + moment().format("MM-DD-YYYY H:m:ss") + ".zip")
			dispatch(ACTION.setUploadList(uploads));
		});
	};

	const checkGroup = (group, errorCount) => {
		if (group.lineItems) {
			group.lineItems.forEach((lineItem) => {
				lineItem.forEach((field) => {
					if (field.error === true) {
						errorCount++;
					}
				});
			});
		}
		if (group.fields) {
			group.fields.forEach((field) => {
				if (field.error === true) {
					errorCount++;
				}
			});
		}
		//for dense row line sections / entities
		if (group.entities && group.entities.length) {
			group.entities.forEach((entity) => {
				if (entity.sections && entity.sections.length) {
					entity.sections.forEach((section) => {
						if (section.groups && section.groups.length) {
							section.groups.forEach((group) => {
								errorCount = checkGroup(group, errorCount);
							});
						}
					})
				}
			});
		}
		return errorCount;
	}

	const formState = selectState(card ? card.formName : null);
	const validateCurrentCard = () => {
		// Determine whether or not there are fields with errors for this card
		// @TODO how are we calculating a card statusId once errors are resolved?
		// @TODO will users be able to edit the card in a complete state? Should I update error status on card if in complete state?
		// @TODO move to usePageFramework?
		let errorCount = 0;
		if (formState && card.statusId !== CARDSTATUS.COMPLETED) {
			formState.forEach((section) => {
				if (section && section.length > 0) {
					section.forEach((eachSection) => {
						if (eachSection.groups) {
							eachSection.groups.forEach((group) => {
								errorCount = checkGroup(group, errorCount);
							});
						}
					});
				} else if (section.groups) {
					section.groups.forEach((group) => {
						errorCount = checkGroup(group, errorCount);
					});
				} else {
					errorCount = checkGroup(section, errorCount);
				}
			});
			for (const key in currentEntity) {
				if (Object.hasOwnProperty.call(currentEntity, key)) {
					const section = currentEntity[key];
					if (section && section.length > 0) {
						// eslint-disable-next-line
						section.forEach((eachSection) => {
							if (eachSection.groups) {
								eachSection.groups.forEach((group) => {
									errorCount = checkGroup(group, errorCount);
								});
							}
						});
					} else if (section.groups) {
						// eslint-disable-next-line
						section.groups.forEach((group) => {
							errorCount = checkGroup(group, errorCount);
						});
					} else {
						errorCount = checkGroup(section, errorCount);
					}
				}
			}
			currentEntity = {};
			if (errorCount > 0) {
				// ERRORS
				updateStatus(CARDSTATUS.ERRORS);
			}
			if (card.statusId === CARDSTATUS.ERRORS && errorCount === 0) {
				updateStatus(CARDSTATUS.IN_PROGRESS);
			}
		}
	};

	const setCardsRequired = (requiredCards, isSetRequiredCards) => {

		dashboard.forEach((cat) => {
			cat.cards.forEach((card) => {
				if (requiredCards.length === 1) {
					if (card.formName === requiredCards[0]) {
						card.isRequired = isSetRequiredCards;
					}
				} else {
					requiredCards.forEach(requiredCard => {
						if (card.formName === requiredCard.card) {
							card.isRequired = requiredCard.isSetRequired;
						}
					})
				}
			});
		});

		dispatch(ACTION.setDashboard(dashboard));
		const dashboardData = { dashboard: dashboard };

		const { summary } = calculateDashboardSummary(dashboard);
		const data = { dashboardSummary: summary };

		api.put(`/organizers/${organizerId ?? ''}/dashboard/${dashboardId}`, dashboardData).catch((error) => {
			console.log(error);
			console.log('Unable to save dashboard');
			dispatch(ACTION.setIsSaveSuccess(false));
		});

		api.put(`/organizers/${organizerId ?? ''}`, data).catch((error) => {
			console.log(error);
			console.log('Unable to save dashboardSummary');
			dispatch(ACTION.setIsSaveSuccess(false));
		});
	};

	return {
		page,
		card,
		currentCardKey,
		clearFormState,
		client,
		history,
		dashboard,
		dispatch,
		screenSize,
		selectState,
		setGlobalFormState,
		location,
		uploadList,
		updateCard,
		updatePage,
		updateStatusCard,
		updateStatus,
		updateVisibleDashboardCards,
		updateSortedDashboardCards,
		onFileUpload,
		uploadFiles,
		onFileRemoval,
		onFilePreview,
		onFileRename,
		downloadFile,
		downloadAllFiles,
		REDUX,
		NAV,
		ACTION,
		CARDSTATUS,
		INPUTSTATE,
		// getData,
		validateCurrentCard,
		appType,
		setCardsRequired,
		organizerId,
		moveFile,
        buildDocumentPreview,
		renderTiffImage
	};
}

export default usePageFramework;

export {
	currentYear,
};